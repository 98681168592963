import React, { useState } from 'react';
import { Heading } from '../../components';
import { isArrayLength, stackDisabledToLast } from '../../util/genericHelpers';
import classNames from 'classnames';
import {
  activitiesOptions,
  arrivalHourOptions,
  bookingLeadTimeOptions,
  departureHourOptions,
  essentialsOptions,
  extrasOptions,
  generalOptions,
  overnightStaysOptions,
  parkingLocationOptions,
  roommateOptions,
  vehicleHeightOptions,
  vehicleLengthOptions,
  vehicleTypesOptions,
} from '../../util/fieldsOptions';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../util/reactIntl';
import css from './ListingPage.module.css';

// Reusable Amenity Component
const Amenity = ({ icon, label, shouldHide, disabled, isGeneral }) => {
  if (shouldHide && disabled) {
    return <></>;
  }

  return (
    <div
      className={classNames(css.amenitieBlock, {
        [css.disabled]: disabled,
        [css.isGeneralAmenityBlock]: isGeneral,
      })}
    >
      <img src={icon} alt={label} />
      <span>{label}</span>
    </div>
  );
};

// Function to handle description and toggle visibility
function useToggleDescription(initialValue = false, maxLength = 365) {
  const [showFull, setShowFull] = useState(initialValue);

  const toggleDescription = () => {
    setShowFull(!showFull);
  };

  const isLong = text => text?.length > maxLength;

  const displayText = text =>
    isLong(text) && !showFull ? `${text.substring(0, maxLength)}...` : text;

  return [showFull, toggleDescription, isLong, displayText];
}

const SectionDetailsMaybe = props => {
  const {
    description,
    publicData,
    isInstantBooking,
    isInfoOnly = false,
    tips,
    renderGoogleAds = null,
  } = props;

  const intl = useIntl();

  const renderInfoOrPlaceholder = info => (
    <span className={css.detailValue}>
      {info
        ? info
        : intl.formatMessage({
            id: 'SectionDetailsMaybe.renderInfoOrPlaceholder',
          })}
    </span>
  );

  const [
    showFullDescription,
    toggleDescription,
    isLongDescription,
    displayedDescription,
  ] = useToggleDescription(false);
  const [
    showFullTipsDescription,
    toggleTipsDescription,
    isLongTipsDescription,
    displayedTipsDescription,
  ] = useToggleDescription(false);
  const [
    showFullRulesDescription,
    toggleRulesDescription,
    isLongRulesDescription,
    displayedRulesDescription,
  ] = useToggleDescription(false);

  // Define a function to find an option by key in an array
  function findOptionByKey(optionsArray, key) {
    return optionsArray.find(o => o.key === key);
  }

  // Use the findOptionByKey function to find options for each property
  const arrivalTime = findOptionByKey(arrivalHourOptions, publicData?.arrivalTime);
  const departureTime = findOptionByKey(departureHourOptions, publicData?.departureTime);
  const bookingLeadTime = findOptionByKey(bookingLeadTimeOptions, publicData?.bookingLeadTime);
  const maximumStay = findOptionByKey(overnightStaysOptions, publicData?.nightsPerBooking);
  const vehicleLength = findOptionByKey(vehicleLengthOptions, publicData?.vehicleLength);
  const vehicleHeight = findOptionByKey(vehicleHeightOptions, publicData?.vehicleHeight);

  // Define a function to check if an array has elements and if the first element is truthy
  function hasFirstElement(array) {
    return isArrayLength(array) && array[0];
  }

  // Use the hasFirstElement function to simplify checks for each property
  const isNoRoomateSelected =
    (!publicData?.animalsOnSite && !publicData?.noAnimalsOnSite) ||
    hasFirstElement(publicData?.noAnimalsOnSite);
  const isNoActivitiesSelected = hasFirstElement(publicData?.noActivities);
  const isNoEssentialsSelected = hasFirstElement(publicData?.noEssentials);
  const isNoExtrasSelected = hasFirstElement(publicData?.noExtras);
  const isNoTravelTypesSelected = hasFirstElement(publicData?.noTravelTypes);
  const isNoGeneralSelected = hasFirstElement(publicData?.noGeneral);

  return (
    <div className={css.sectionDetails}>
      <div className={css.description}>
        <p>{displayedDescription(description)}</p>
        {isLongDescription(description) && (
          <p className={css.showMore} onClick={toggleDescription}>
            <strong>
              {intl.formatMessage({
                id: showFullDescription
                  ? 'SectionDetailsMaybe.showLess'
                  : 'SectionDetailsMaybe.showMore',
              })}
            </strong>
          </p>
        )}
      </div>
      <div className={css.amenitiesSec}>
        {stackDisabledToLast(publicData?.general, generalOptions).map((e, i) => (
          <Amenity
            key={i}
            icon={e.icon}
            label={e.label}
            isGeneral={true}
            disabled={
              isNoGeneralSelected ||
              (isArrayLength(publicData?.general) && !publicData?.general.includes(e.key))
            }
          />
        ))}
      </div>
      <Heading as="h2" rootClassName={css.orderPanelTitle}>
        {intl.formatMessage({
          id: 'SectionDetailsMaybe.bookingInformation',
        })}
      </Heading>
      <ul className={css.details}>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.bookingLabel',
            })}
          </span>
          {renderInfoOrPlaceholder(bookingLeadTime?.label)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.latestArrival',
            })}
          </span>
          {renderInfoOrPlaceholder(arrivalTime?.label)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.latestDeparture',
            })}
          </span>
          {renderInfoOrPlaceholder(departureTime?.label)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.maximumStay',
            })}
          </span>
          {renderInfoOrPlaceholder(maximumStay?.label)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.totalPitches',
            })}
          </span>
          {renderInfoOrPlaceholder(publicData?.totalPitches)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.bookingType',
            })}
          </span>
          <span className={css.detailValue}>
            {' '}
            {intl.formatMessage({
              id: isInfoOnly
                ? 'SectionDetailsMaybe.renderInfoOrPlaceholder'
                : isInstantBooking
                ? 'SectionDetailsMaybe.isInstantBooking'
                : 'SectionDetailsMaybe.normalBooking',
            })}
          </span>
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'BookingDatesForm.personsIncluded',
            })}
          </span>
          {renderInfoOrPlaceholder(publicData?.personsIncludedInBasePrice)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'BookingDatesForm.maxPersons',
            })}
          </span>
          {renderInfoOrPlaceholder(publicData?.maxPersons)}
        </li>
      </ul>
      {renderGoogleAds}
      <Heading as="h2" rootClassName={css.orderPanelTitle}>
        {intl.formatMessage({
          id: 'SectionDetailsMaybe.vehicleInformation',
        })}
      </Heading>
      <ul className={css.details}>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.vehicleHeight',
            })}
          </span>
          {renderInfoOrPlaceholder(vehicleHeight?.label)}
        </li>
        <li className={css.detailsRow}>
          <span className={css.detailLabel}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.vehicleLength',
            })}
          </span>
          {renderInfoOrPlaceholder(vehicleLength?.label)}
        </li>
      </ul>
      <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
        {isInfoOnly ? (
          <span className={css.noVehicleInformation}>
            {' '}
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.noVehicleInformation',
            })}
          </span>
        ) : (
          stackDisabledToLast(publicData?.vehicleTypes, vehicleTypesOptions).map((e, i) => (
            <Amenity
              key={i}
              icon={e.icon}
              label={e.label}
              disabled={!publicData?.vehicleTypes?.includes(e?.key)}
            />
          ))
        )}
      </div>
      {!isNoEssentialsSelected ? (
        <>
          {' '}
          <Heading as="h2" rootClassName={css.orderPanelTitle}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.campingEssentials',
            })}
          </Heading>
          <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
            {stackDisabledToLast(publicData?.essentials, essentialsOptions).map((e, i) => (
              <Amenity
                key={i}
                icon={e.icon}
                label={e.label}
                disabled={isNoEssentialsSelected || !publicData?.essentials?.includes(e?.key)}
              />
            ))}
          </div>
        </>
      ) : null}{' '}
      {renderGoogleAds}
      {!isNoExtrasSelected ? (
        <>
          <Heading as="h2" rootClassName={css.orderPanelTitle}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.campingExtras',
            })}
          </Heading>
          <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
            {stackDisabledToLast(publicData?.extras, extrasOptions).map((e, i) => (
              <Amenity
                key={i}
                icon={e.icon}
                label={e.label}
                shouldHide
                disabled={isNoExtrasSelected || !publicData?.extras?.includes(e?.key)}
              />
            ))}
          </div>
        </>
      ) : null}{' '}
      {!publicData?.parkingLocation ? (
        <></>
      ) : !isNoTravelTypesSelected ? (
        <>
          <Heading as="h2" rootClassName={css.orderPanelTitle}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.pitchInformation',
            })}
          </Heading>
          <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
            {stackDisabledToLast(publicData?.parkingLocation, parkingLocationOptions).map(
              (e, i) => (
                <Amenity
                  key={i}
                  shouldHide
                  icon={
                    e?.icon ||
                    'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/7+SHEEP+REV+1_s.png'
                  }
                  label={e.label}
                  disabled={!publicData?.parkingLocation?.includes(e?.key)}
                />
              )
            )}
          </div>
        </>
      ) : null}
      {publicData?.houseRules ? (
        <div className={css.description}>
          <p>{displayedRulesDescription(publicData?.houseRules)}</p>
          {isLongRulesDescription(publicData?.houseRules) && (
            <p className={css.showMore} onClick={toggleRulesDescription}>
              <strong>
                {intl.formatMessage({
                  id: showFullRulesDescription
                    ? 'SectionDetailsMaybe.showLess'
                    : 'SectionDetailsMaybe.showMore',
                })}
              </strong>
            </p>
          )}
        </div>
      ) : null}
      {renderGoogleAds}
      {!isNoRoomateSelected ? (
        <>
          <Heading as="h2" rootClassName={css.orderPanelTitle}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.roommateInformation',
            })}
          </Heading>
          <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
            {stackDisabledToLast(publicData?.animalsOnSite, roommateOptions).map((e, i) => (
              <Amenity
                key={i}
                icon={e?.icon}
                shouldHide
                label={e.label}
                disabled={isNoRoomateSelected || !publicData?.animalsOnSite?.includes(e?.key)}
              />
            ))}
          </div>
        </>
      ) : null}{' '}
      {!isNoActivitiesSelected ? (
        <>
          <Heading as="h2" rootClassName={css.orderPanelTitle}>
            {intl.formatMessage({
              id: 'SectionDetailsMaybe.regionAndActivities',
            })}
          </Heading>
          {tips ? (
            <div className={css.description}>
              <p>{displayedTipsDescription(tips)}</p>
              {isLongTipsDescription(tips) && (
                <p className={css.showMore} onClick={toggleTipsDescription}>
                  <strong>
                    {intl.formatMessage({
                      id: showFullTipsDescription
                        ? 'SectionDetailsMaybe.showLess'
                        : 'SectionDetailsMaybe.showMore',
                    })}
                  </strong>
                </p>
              )}
            </div>
          ) : null}
          <div className={classNames(css.amenitiesSec, css.vehicleAmenitiesSec)}>
            {stackDisabledToLast(publicData?.activities, activitiesOptions).map((e, i) => (
              <Amenity
                key={i}
                icon={e?.icon}
                label={e.label}
                shouldHide
                disabled={isNoActivitiesSelected || !publicData?.activities?.includes(e?.key)}
              />
            ))}
          </div>
        </>
      ) : null}
      {renderGoogleAds}
    </div>
  );
};

export default SectionDetailsMaybe;
