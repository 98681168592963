import React from 'react';
import { Icons } from '../../components';
import classNames from 'classnames';
import { onSendEmail } from '../../util/genericHelpers';

import css from './ListingPage.module.css';

const SectionTextMaybe = props => {
  const { intl, authorDisplayName, publicData, isInfoOnly, locationDetails } = props;

  const hostedByTitle = intl.formatMessage({ id: 'SectionTextMaybe.hostedByTitle' });

  const location = publicData?.location?.address;

  if (isInfoOnly) {
    return (
      <div className={css.sectionText}>
        <div className={classNames(css.location, css.locationPin)}>
          <Icons name="location" />
          <span>
            {location ? (
              <strong>{location}</strong>
            ) : (
              <strong>
                {locationDetails?.city}, {locationDetails?.zip}, {locationDetails?.country}{' '}
              </strong>
            )}
          </span>
        </div>
        <div className={classNames(css.location, css.profileIcon)}>
          <Icons name="profile" />
          <span className={css.claimByTagline}>
            {' '}
            <strong>{hostedByTitle}</strong>{' '}
            {intl.formatMessage({ id: 'SectionTextMaybe.notClaim' })}{' '}
            <span
              className={css.claimBtnInline}
              role="button"
              onClick={() => onSendEmail('', 'team@youandaview.com')}
            >
              {intl.formatMessage({ id: 'SectionTextMaybe.claimnow' })}
            </span>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={css.sectionText}>
      <div className={classNames(css.location, css.locationPin)}>
        <Icons name="location" />
        <span>
          {location ? (
            <strong>{location}</strong>
          ) : (
            <strong>
              {locationDetails?.city}, {locationDetails?.zip}, {locationDetails?.country}{' '}
            </strong>
          )}
        </span>
      </div>
      <div className={classNames(css.location, css.profileIcon)}>
        <Icons name="profile" />
        <span>
          <strong>{hostedByTitle}</strong> {authorDisplayName}
        </span>
      </div>
    </div>
  );
};

export default SectionTextMaybe;
